import React, { useState, useEffect } from "react";
import Icon from "react-crud-icons";

const Risk = (props) => {
  const [num, setNum] = useState(0);
  const [stock, setStock] = useState("Stock1");
  const [alertType, setAlertType] = useState("Price");
  const [savedAmount, setSavedAmount] = useState(0);
  const stocks = ["Stock1", "stock2"];
  const alarms = ["Price", "Yield", "date"];
  const [alert, setAlert] = useState([{}]);

  useEffect(() => {
    setAlert(props.configData.alerts);
  }, [props.configData.alerts]);

  const addAlert = () => {
    setAlert([...alert, { stock: stock, alarm: alertType, num: num }]);
    props.updateConfig("alerts", [
      ...alert,
      { stock: stock, alarm: alertType, num: num },
    ]);
  };
  const removeAlert = (i) => {
    const newItems = [...alert];
    // Remove the item at the specified index
    newItems.splice(i, 1);
    // Update the state
    setAlert(newItems);
    props.updateConfig("alerts", newItems);
  };
  const handleStockChange = (event) => {
    setStock(event.target.value);
  };

  const handleAlertChnage = (event) => {
    setAlertType(event.target.value);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <h1
        style={{
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          fontSize: "1.5em",
          fontWeight: 700,
          color: "#333",
          margin: "0.5em 0",
          lineHeight: 1.2,
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          borderBottom: "2px solid #e2e2e2",
          paddingBottom: "0.3em",
        }}
      >
        Alerts
      </h1>

      <div
        style={{
          marginRight: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: 10,
        }}
      >
        <select
          id="options"
          value={stock}
          onChange={handleStockChange}
          style={{ padding: "5px", display: "block", width: 120 }}
        >
          {stocks.map((sector, index) => (
            <option key={index} value={sector}>
              {sector}
            </option>
          ))}
        </select>
        <select
          id="options"
          value={alertType}
          onChange={handleAlertChnage}
          style={{ padding: "5px", display: "block", width: 100 }}
        >
          {alarms.map((sector, index) => (
            <option key={index} value={sector}>
              {sector}
            </option>
          ))}
        </select>

        <input
          type="number"
          id="percentage"
          value={num}
          onChange={(e) => setNum(e.target.value)}
          style={{
            padding: "5px",
            marginRight: "10px",
            width: "60px",
            display: "block",
          }}
        />
        <Icon
          name="add"
          tooltip="Add"
          theme="light"
          size="medium"
          onClick={() => addAlert()}
        />
      </div>
      <div>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {alert.map((x, index) => (
            <li>
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "10px",
                }}
              ></span>
              {x.stock}- {x.alarm} - {x.num}
              <Icon
                name="remove"
                tooltip="remove"
                theme="light"
                size="small"
                onClick={() => removeAlert(index)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Risk;
