module.exports = [
    "#E8DBCD", // orange red
    "#D5DbCE", // gold
    "#8F8861", // sea green
    "#F0EFE6", // dark sea green
    "#EAEAEA", // crimson
    "#C6C9D2", // dark turquoise
    "#DCCFC1", // dark magenta
    "#B8AEA8", // hot pink
    "#D0EEF2", // coral
    "#FCE6D0", // spring green
    "#98A7B8", // turquoise
    "#7B9080", // medium slate blue
    "#3E4B4A", // dark violet
    "#CCB363", // forest green
    "#C27E35", // tomato
    "#6F7D7B", // olive drab
    "#76290B", // dark orchid
    "#1E3226", // firebrick
    "#008080", // teal
    "#4B0082", // indigo
    "#00FA9A", // medium spring green
    "#FFC0CB", // pink
    "#0000CD", // medium blue
    "#FFA07A", // light salmon
    "#66CDAA", // medium aquamarine
    "#9370DB", // medium purple
    "#F08080", // light coral
    "#1E90FF", // dodger blue
    "#FF8C00", // dark orange
    "#48D1CC", // medium turquoise
    "#FF1493", // deep pink
    "#008000", // green
    "#00BFFF", // deep sky blue
    "#DDA0DD", // plum
    "#20B2AA", // light sea green
    "#ADFF2F", // green yellow
    "#00FF00", // lime
    "#FF00FF", // magenta
    "#4169E1", // royal blue
    "#FF4500", // orange red
    "#FFD700", // gold
    "#2E8B57", // sea green
    "#8FBC8F", // dark sea green
    "#DC143C", // crimson
    "#00CED1", // dark turquoise
    "#8B008B", // dark magenta
    "#FF69B4", // hot pink
    "#FF7F50", // coral
    "#00FF7F", // spring green
    "#40E0D0", // turquoise
    "#7B68EE", // medium slate blue
    "#9400D3", // dark violet
    "#228B22" // forest green
  ]