import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import DataContext from "../DataContext";

function ImportModalCSV(props) {
  const [csvFile, setCsvFile] = useState(null);
  const { data } = useContext(DataContext);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const reader = new FileReader();

    reader.onload = function (event) {
      const csvData = event.target.result; // Get the CSV data as a string
      const extractedData = extractDataFromCSV(csvData); // Use the previously defined function to extract data
      setCsvFile(extractedData); // Log the extracted data to the console or do something else with it
    };

    // Read the uploaded file as text
    reader.readAsText(file);
  };

  const handleImport = () => {
    // Handle the import logic here
    axios
      .post(process.env.REACT_APP_API_IMPORT, [csvFile, data.email], {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      })
      .then((res) => {
        console.log(res);
      });
  };

  const handleDownload = () => {
    // Create a dummy CSV data
    const csvData = `Symbol, Cost, NoOfShares, DivDate, Price
    NESF.L, 999, 1226, , 10`;
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "example.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  function extractDataFromCSV(csvData) {
    const rows = csvData.split("\n"); // Split CSV data into rows
    const headers = rows[0].split(",").map((header) => header.trim()); // Extract headers

    const extractedData = [];

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(",").map((cell) => cell.trim()); // Split row into cells
      if (row.length !== headers.length) continue; // Skip rows with inconsistent number of cells
      const rowData = {};
      for (let j = 0; j < headers.length; j++) {
        rowData[headers[j]] = isNaN(row[j]) ? row[j] : parseFloat(row[j]); // Parse numeric cells as numbers
      }
      extractedData.push(rowData);
    }

    return extractedData;
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Import Portfolio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <a href="#" onClick={() => handleDownload()}>
          Download Templplate (CSV)
        </a>
        <Form>
          <Form.Group>
            <Form.Control
              type="file"
              id="csvFileInput"
              label="Choose CSV file"
              onChange={(e) => handleFileChange(e)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleImport()}>
          Import
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportModalCSV;
