import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Icon from "react-crud-icons";

const StockSearch = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [focus, setFocus] = useState(false);
  const [wishListStock, setWishListStock] = useState({});

  useEffect(() => {
    setSelectedStocks(props.configData.wishlist);
  }, [props.configData.wishlist]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();

    // Simulated API call to fetch search results based on search query
    const fakeSearchResults = [
      { symbol: "AAPL", name: "Apple Inc." },
      { symbol: "MSFT", name: "Microsoft Corporation" },
      { symbol: "GOOGL", name: "Alphabet Inc." },
      { symbol: "AMZN", name: "Amazon.com Inc." },
      { symbol: "FB", name: "Meta Platforms Inc." },
    ];
    setSearchResults(fakeSearchResults);
  };

  const handleResultClick = () => {
    setFocus(false);
    setSelectedStocks([...selectedStocks, wishListStock]);
    props.updateConfig("wishlist", [...selectedStocks, wishListStock]);
  };

  const removeAlert = (i) => {
    const newItems = [...selectedStocks];
    // Remove the item at the specified index
    newItems.splice(i, 1);
    // Update the state
    setSelectedStocks(newItems);
    props.updateConfig("wishlist", newItems);
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "500px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          fontSize: "1.5em",
          fontWeight: 700,
          color: "#333",
          margin: "0.5em 0",
          lineHeight: 1.2,
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          borderBottom: "2px solid #e2e2e2",
          paddingBottom: "0.3em",
        }}
      >
        Wishlist
      </h1>
      <form onSubmit={handleSearchSubmit} style={{ marginBottom: "20px" }}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search for a stock..."
          style={{ padding: "8px", marginRight: "10px", width: "70%" }}
          onFocus={() => setFocus(true)}
          onBlur={(e) => {
            if (e.target.value.trim() === "") {
              setSearchResults([]);
            }
          }}
        />
        <Button type="submit">Search</Button>
      </form>
      <div>
        <ul
          style={{
            listStyle: "none",
            position: "absolute",
            padding: 0, // Remove default padding
          }}
        >
          {focus
            ? searchResults.map((result, index) => (
                <li
                  onClick={(e) => handleResultClick(e)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderStyle: "solid",
                    transition: "background-color 0.3s ease",
                    backgroundColor: "white",
                    marginBottom: "1px", // Add margin between list items
                  }}
                  key={index}
                  onMouseEnter={(e) => {
                    setWishListStock(result.name);
                    e.target.style.backgroundColor = "lightgray";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "white";
                  }}
                >
                  {result.name}
                </li>
              ))
            : null}
        </ul>
        <ul>
          {selectedStocks.map((stock, index) => (
            <li key={index}>
              {stock}{" "}
              <Icon
                name="remove"
                tooltip="remove"
                theme="light"
                size="small"
                onClick={() => removeAlert(index)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StockSearch;
