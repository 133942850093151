import colourCodes from "../colours";

function Legend(props) {
  return (
    <ul
      style={{
        listStyleType: "none",
        marginTop: 20,
        display: "flex",
        padding: 0,
      }}
    >
      {props.name.map((x, i) => {
        // Splitting the name into words
        const words = x.name.split(" ");

        return (
          <li key={i} style={{ alignItems: "center", marginRight: 20 }}>
            <div
              style={{
                marginRight: 5,
                display: "flex",
                textAlign: "start",
              }}
            >
              <span style={{ color: colourCodes[i], fontSize: 14 }}>
                {/* Render each word inline */}
                {words.join(" ")}
              </span>
            </div>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: colourCodes[i],
                marginLeft: 5,
              }}
            ></div>
          </li>
        );
      })}
    </ul>
  );
}

export default Legend;
