
import axios from 'axios';
import axiosThrottle from 'axios-request-throttle'
import React from "react";
import Icon from "react-crud-icons";
import universalStyle from '../universalStyle'

import "../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";

axiosThrottle.use(axios, { requestsPerSecond: 4 });



function PortfolioTable(props) {

  function sumArray(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].dividend;
    }
    return sum;
  }

      return (
    <table class="table table-hover">
      <thead style={{fontWeight: 'bold', fontSize:'5rem'}}>
        <tr >
          <th scope="col" ><h1 style={{color:universalStyle.colours.primary}}>#</h1></th>
          <th style = {{textAlign:'center'}}scope="col"><h1 style={{color:universalStyle.colours.primary}}>Stock</h1></th>
          <th style = {{textAlign:'center'}}scope="col"><h1 style={{color:universalStyle.colours.primary}}>Cost</h1></th>
          <th style = {{textAlign:'center'}}scope="col"><h1 style={{color:universalStyle.colours.primary}}>Value</h1></th>
          <th style = {{textAlign:'center'}}scope="col"><h1 style={{color:universalStyle.colours.primary}}>Gain(Loss)</h1></th>
          <th style = {{textAlign:'center'}}scope="col"><h1 style={{color:universalStyle.colours.primary}}>Dividends</h1></th>
          <th scope="col"></th>
        </tr>
      </thead>
      {props.data.map((n,index) =>    

<tbody key={n._id}>
<tr >
<th scope="row" >{index+1}</th>
<td style={{textAlign:'center',color:universalStyle.colours.secondary}}onClick={()=>props.open(n)}><img style={{cursor:'pointer'}} src={n.image}></img></td>
<td style = {{textAlign:'center',color:universalStyle.colours.secondary}}>{new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(n.cost) } </td> 

<td style = {{textAlign:'center',color:universalStyle.colours.secondary}}>{ new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(n.price*n.noOfShares)}</td>
<td style={n.price*n.noOfShares-n.cost>0?{color:'green',textAlign:'center'}:{color:'red',paddingLeft:15,textAlign:'center'}}>{ new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(n.price*n.noOfShares-n.cost) }</td>
<td style={{textAlign:'center',color:universalStyle.colours.secondary}}>{'£'+ sumArray(n.divDate)}</td>
<td>
  <Icon
name="edit"
tooltip="Edit"
theme="light"
size="medium"
onClick={() =>props.handleShow(n._id,n.cost,n.symbol,n.noOfShares,n.divDate)}
/>
<Icon
name="remove"
tooltip="Remove"
theme="light"
size="medium"
onClick={() =>props.deleteStock(n._id,n.cost,n.symbol,n.noOfShares)}
/>
</td>
</tr>
</tbody> )}
</table>
  );
}

export default PortfolioTable;



