import React, { useEffect, useState } from "react";
import ProgressBar from "./progress";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import colourCodes from "../colours";
import axios from "axios";

const AmountInput = (props) => {
  const [divAmount, setDivAmount] = useState("");
  const [savedDivAmount, setDivSavedAmount] = useState("");
  const cleanedStr = props.div.replace(/[^0-9.]/g, "");

  useEffect(() => {
    setDivSavedAmount(props.configData.divGoal);
  }, [props.configData.divGoal]);

  const handleDivInputChange = (event) => {
    setDivAmount(event.target.value);
  };

  const handleDivInputBlur = () => {
    setDivSavedAmount(divAmount);
    props.updateConfig("divGoal", divAmount);
    setDivAmount("");
  };

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <h1
        style={{
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          fontSize: "1.5em",
          fontWeight: 700,
          color: "#333",
          margin: "0.5em 0",
          lineHeight: 1.2,
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          borderBottom: "2px solid #e2e2e2",
          paddingBottom: "0.3em",
        }}
      >
        Dividend Goal
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ transform: "translateY(10px)" }}>
          <p>
            Monthly Dividend Goal:{savedDivAmount ? "£" : ""}
            {savedDivAmount}
          </p>
        </div>
        <input
          type="number"
          id="amountDivInput"
          value={divAmount}
          onChange={handleDivInputChange}
          onBlur={handleDivInputBlur}
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            margin: "10px",
          }}
        />
      </div>
      <ProgressBar
        current={Number(parseFloat((cleanedStr / 12).toFixed(2)))}
        total={savedDivAmount}
      />
      <>
        <ResponsiveContainer height={600} width="100%">
          <BarChart
            width={1300}
            height={600}
            data={props.barData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {props.data.map((n, index) => (
              <Bar dataKey={n.symbol} stackId="a" fill={colourCodes[index]} />
            ))}
            <Line
              type="monotone"
              dataKey="Total"
              stroke="#E8DBCD"
              fill="#E8DBCD"
              strokeWidth={4}
            />
          </BarChart>
        </ResponsiveContainer>
      </>
    </div>
  );
};

export default AmountInput;
