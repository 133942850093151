// DataContext.js

import React, { createContext, useState, useEffect } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    // Initialize state from localStorage if available
    const savedData = localStorage.getItem("data");

    return savedData ? JSON.parse(savedData) : { token: "", email: "" };
  });

  // Update localStorage when context data changes
  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data));
  }, [data]);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
