


function Toast(props) {
  
  return (
    <div style = {{position:'fixed',top:100}}class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong class="me-auto">Update</strong>
 
    
    </div>
    <div class="toast-body">
      {props.message}
    </div>
  </div>
    
  );
}



export default Toast;