import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import Icon from "react-crud-icons";
import InputPassword from "../components/inputPassword";
import Dropdown from "react-bootstrap/Dropdown";
import DataContext from "../DataContext";

const SettingsPage = () => {
  const [show, setShow] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("£ Pounds");
  const { data } = useContext(DataContext);

  const [settings, setSettings] = useState({
    /* initial settings state */
  });
  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    // You can perform any additional actions here when the currency changes
  };

  // Fetch settings from server
  useEffect(() => {
    // fetch settings and update state
  }, []);

  const saveSettings = () => {
    // API call to save settings
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "#FFFFFF",
          marginRight: "auto",
          marginLeft: "auto",
          marginBottom: 20,
          borderRadius: 15,
          boxShadow: "10px 10px 10px 10px",
          width: 1000,
        }}
      >
        <h1 style={{ marginBottom: 40 }}>Settings</h1>
        <div>
          <h4>User Details</h4>
          <text>Email: </text>
          <text>{data.email}</text>
          <Icon
            name="edit"
            tooltip="Edit"
            theme="light"
            size="medium"
            onClick={() => open()}
          />
          <br></br>
          <text>Password:</text> <text>●●●●●●●●</text>
          <Icon
            name="edit"
            tooltip="Edit"
            theme="light"
            size="medium"
            onClick={() => open()}
          />
          <h4>Preferences</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <text>Currency: </text>
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="currency-dropdown"
                size="sm"
                style={{ transform: "translate(0px,-6px)" }}
              >
                {selectedCurrency}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleCurrencyChange("£ Pounds")}>
                  £ Pounds
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleCurrencyChange("$ Dollars")}
                >
                  $ Dollars
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Button style={{ marginBottom: 20 }} onClick={saveSettings}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default SettingsPage;
