// DivTotalContext.js

import React, { createContext, useState, useEffect } from "react";

const DivTotalContext = createContext();

export const DivProvider = ({ children }) => {
  const [div, setDiv] = useState(() => {
    // Initialize state from localStorage if available
    const savedData = localStorage.getItem("div");
    return savedData;
  });

  // Update localStorage when context data changes
  useEffect(() => {
    console.log(div);
    //  localStorage.setItem("div", JSON.stringify(div));
    localStorage.setItem("div", 0);
  }, [div]);

  return (
    <DivTotalContext.Provider value={{ div, setDiv }}>
      {children}
    </DivTotalContext.Provider>
  );
};

export default DivTotalContext;
