import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";

function Configuration(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Configuration</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <label
            for="exampleSelect1"
            class="form-label mt-4"
            style={{ marginRight: 10, translate: "0px -6px" }}
          >
            Data Set
          </label>

          <select
            style={{ flex: 1 }}
            class="form-select"
            defaultValue={props.value}
            id="exampleSelect1"
            onChange={(e, i) => {
              props.handleChange(e, i);
            }}
          >
            <option value={"Cost"}>Cost</option>
            <option value={"Value"}>Value</option>
          </select>
          <label
            for="exampleSelect1"
            class="form-label mt-4"
            style={{ marginRight: 10, translate: "0px -6px" }}
          >
            Catagory
          </label>

          <select
            style={{ flex: 1 }}
            class="form-select"
            id="exampleSelect2"
            defaultValue={props.catagory}
            onChange={(e, i) => {
              props.handleChangeCat(e, i);
            }}
          >
            <option value={"Stock"}>Stock</option>
            <option value={"Sector"}>Sector</option>
          </select>
          <label
            for="exampleSelect1"
            class="form-label mt-4"
            style={{ marginRight: 10, translate: "0px -6px" }}
          >
            Inlcude Line
          </label>

          <select
            style={{ flex: 1 }}
            class="form-select"
            defaultValue={props.valueBar}
            id="exampleSelect1"
            onChange={(e, i) => {
              props.handleChangeBar(e, i);
            }}
          >
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No</option>
          </select>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Configuration;
