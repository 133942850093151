import { useEffect, useState, useContext, useRef } from "react";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  linearGradient,
  stop,
  defs,
  Area,
} from "recharts";
import React from "react";
import axios from "axios";
import colourCodes from "../colours";
import StockModalInfo from "../components/viewStockModal";
import Button from "react-bootstrap/Button";
import Configuration from "../components/Configuration";
import { Spinner } from "react-bootstrap";
import PieLegend from "../components/legend";
import { useMediaQuery } from "react-responsive";
import DataContext from "../DataContext";

function Overview(props) {
  const [sector, setSector] = useState([]);
  const { data } = useContext(DataContext);

  const [pieDataCost, setPieDataCost] = useState([]);
  const [pieDataValue, setPieDataValue] = useState([]);
  const [pieDataYeild, setPieYeild] = useState([]);
  const [pieDataYeildSector, setPieYeildSector] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [barData, setBarData] = useState([{ x: "x" }]);
  const [barDataSector, setBarDataSector] = useState([{ x: "x" }]);
  const [value, setValue] = useState("Cost");
  const [name, setName] = useState("");
  const [details, setDetails] = useState({
    divDate: [],
    financials: [[], [], []],
  });
  const [pieDataCostSector, setPieDataCostSector] = useState([]);
  const [pieDataValueSector, setPieDataValueSector] = useState([]);
  const [showConfiguration, setShowConfiguration] = useState(false);
  const [line, showLine] = useState("Yes");

  const [sectorStock, setSectorStock] = useState("Stock");
  const [isLoading, setIsLoading] = useState(false);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1360px)" });
  const isLaptop = useMediaQuery({ query: "(max-width: 1600px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  function onClick(e) {
    open(e);
  }

  function handleChangeCat(e, i) {
    setSectorStock(e.target.value);
  }

  function handleChange(e, i) {
    setValue(e.target.value);
  }
  function setLine(e, i) {
    showLine(e.target.value);
  }
  const close = () => {
    setShowInfo(false);
  };
  const open = (e) => {
    setName(e.name);

    const detail = props.data.find((x) => x.symbol == e.name);
    if (detail) {
      setDetails(detail);
      setShowInfo(true);
    }
  };

  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;

      const findUniqueSectors = () => {
        const uniqueSectorsSet = new Set();
        props.data.forEach((item) => {
          uniqueSectorsSet.add(item.sector);
        });
        return Array.from(uniqueSectorsSet);
      };

      // Update state with unique sector values
      setSector(findUniqueSectors());

      setIsLoading(true);
      axios
        .get(process.env.REACT_APP_API_GRAPHS, {
          params: {
            user: data.email,
            token: data.token,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setPieDataCost(res.data[0][0]);
          setPieDataValue(res.data[0][1]);
          setPieDataCostSector(res.data[0][2]);
          setPieDataValueSector(res.data[0][3]);
          setBarData(res.data[1]);
          setPieYeild(res.data[2][0]);
          setPieYeildSector(res.data[2][1]);
          setBarDataSector(res.data[1][1]);
        });
    }
  }, [props.data]);

  return (
    <div>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <div>
          <div>
            <div
              style={{
                ...styles.whiteBox,
                ...styles.barChart,
              }}
            >
              <h2>Portfolio Value(£)</h2>
              <>
                <ResponsiveContainer height={600} width="100%">
                  <AreaChart
                    width={1000}
                    height={500}
                    data={barData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area
                      type="monotone"
                      dataKey="Total"
                      stroke="black"
                      fill={colourCodes[0]}
                    />
                    <Area
                      type="monotone"
                      dataKey="Value"
                      stroke="red"
                      fill="red"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </>
            </div>
            <div
              style={{
                flexDirection: isTabletOrMobile ? "column" : "row",
                ...styles.parent,
              }}
            >
              <div
                style={{
                  ...styles.whiteBox,
                  ...styles.pieChart,

                  width: isLaptop ? 450 : 650,
                }}
              >
                <div>
                  <Configuration
                    show={showConfiguration}
                    close={setShowConfiguration}
                    handleShow={setShowConfiguration}
                    handleChange={handleChange}
                    handleChangeCat={handleChangeCat}
                    value={value}
                    catagory={sectorStock}
                    valueBar={line}
                    catagoryBar={sectorStock}
                    handleChangeBar={setLine}
                  />
                </div>

                <StockModalInfo
                  handleShow={setShowInfo}
                  show={showInfo}
                  close={close}
                  name={name}
                  details={details}
                  value={props.value}
                />
                <h2 style={{ ...styles.header }}>Stock Split</h2>
                <PieChart
                  width={isLaptop ? 400 : 600}
                  height={isLaptop ? 400 : 600}
                >
                  <Tooltip />
                  <Pie
                    key={2}
                    isAnimationActive={true}
                    onClick={sectorStock === "Stock" ? (e) => onClick(e) : null}
                    data={
                      sectorStock === "Stock"
                        ? value === "Cost"
                          ? pieDataCost
                          : pieDataValue
                        : value === "Cost"
                        ? pieDataCostSector
                        : pieDataValueSector
                    }
                    label={CustomLabel}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={isLaptop ? 100 : 200}
                  />
                </PieChart>
              </div>

              <div
                class="form-group"
                style={{
                  ...styles.whiteBox,
                  ...styles.pieChart,
                  marginLeft: isTabletOrMobile ? 10 : 0,
                  marginRight: isTabletOrMobile ? 0 : 200,
                  width: isLaptop ? 450 : 650,
                }}
              >
                <h2 style={{}}>Dividend Split</h2>
                <div>
                  <PieChart
                    width={isLaptop ? 400 : 600}
                    height={isLaptop ? 400 : 600}
                  >
                    <Tooltip />
                    <Pie
                      onClick={
                        sectorStock === "Stock" ? (e) => onClick(e) : null
                      }
                      isAnimationActive={true}
                      data={
                        sectorStock === "Stock"
                          ? pieDataYeild
                          : pieDataYeildSector
                      }
                      dataKey="value"
                      label={CustomLabel}
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={isLaptop ? 100 : 200}
                    />
                  </PieChart>
                </div>
              </div>
            </div>
            <div
              style={{
                ...styles.whiteBox,
                ...styles.legend,
              }}
            >
              <Button
                onClick={() => setShowConfiguration(true)}
                variant="primary"
                style={{ display: "block", margin: "auto" }}
              >
                Configuration
              </Button>
              <PieLegend
                name={sectorStock === "Stock" ? pieDataCost : pieDataCostSector}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  parent: {
    display: "flex",
    justifyContent: "space-between",
  },
  whiteBox: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    margin: 20,
    marginTop: 40,
    borderRadius: 15,
    offset: 10,
    boxShadow: "10px 10px 10px 10px",
  },

  pieChart: {
    padding: 10,
  },

  barChart: {
    marginLeft: 30,
    marginRight: 30,
    padding: 30,
    minWidth: 400,
  },
  legend: {
    padding: 20,

    maxHeight: 680,
  },
  header: {},
};

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  fill,
  name,
  value,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  // Alternate the radius based on the index
  const radius = outerRadius + (index % 2 === 0 ? 20 : 60); // Short and long lengths
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <line x1={cx} y1={cy} x2={x} y2={y} stroke={fill} strokeWidth={2} />
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        £{value}
      </text>
    </g>
  );
};
export default Overview;
