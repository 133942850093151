import "bootswatch/dist/lux/bootstrap.min.css";
import Register from "./register";
import { Dropdown } from "react-bootstrap";
import Icon from "react-crud-icons";
import { useState, useContext } from "react";
import "./navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import DataContext from "../DataContext";

function Navbar(props) {
  const { data } = useContext(DataContext);
  const { setData } = useContext(DataContext);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };
  const location = useLocation();

  function logOut() {
    setData({ email: null, token: null });

    navigate("/home", { replace: true });
  }

  return (
    <nav
      className="navbar navbar-expand navbar-dark"
      style={{
        height: 100,
        margin: 25,
        borderRadius: 10,
        backgroundColor: "#191919",
        boxShadow: "5px 5px 5px 5px",
      }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/home">
          DivHub
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-target="#navbarColor01"
          aria-expanded
          aria-label="Toggle navigation"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  location.pathname === "/portfolio"
                    ? "active"
                    : "inactive-link"
                }`}
                href="/portfolio"
              >
                Portfolio
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  location.pathname === "/calendar" ? "active" : "inactive-link"
                }`}
                href="/calendar"
              >
                Calendar
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  ${
                  location.pathname === "/overview" ? "active" : "inactive-link"
                }`}
                href="/overview"
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link  ${
                  location.pathname === "/targets" ? "active" : "inactive-link"
                }`}
                href="/targets"
              >
                Targets
              </a>
            </li>
          </ul>
          <span className="navbar-text" style={{ float: "right" }}>
            {data.token ? (
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="logout-dropdown">
                  <Icon
                    name="account"
                    tooltip="account"
                    theme="dark"
                    size="medium"
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item style={{ color: "black" }}>
                    <p onClick={() => navigate("/settings", { replace: true })}>
                      Settings
                    </p>
                  </Dropdown.Item>

                  <Dropdown.Item style={{ color: "black" }} onClick={logOut}>
                    <p>Log Out</p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <p style={{ cursor: "pointer" }} onClick={open}>
                Log In
              </p>
            )}

            <Register setShow={setShow} show={show} close={close} />
          </span>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
