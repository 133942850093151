
import React from 'react';




function Error(props) {



    return (
<div style={{padding:100}}>
   <p>The Servers are down. Please try again later</p>
   </div>         
     
    );
  }
  
  export default Error;
  