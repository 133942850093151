import React from "react";
import { useContext } from "react";

import { Navigate } from "react-router-dom";
import Toast from "./components/toast";
import DataContext from "./DataContext";

// receives component and any other props represented by ...rest
export default function ProtectedRoutes({ children }) {
  const { data } = useContext(DataContext);

  // this route takes other routes assigned to it from the App.js and return the same route if condition is met

  // get cookie from browser if logged in
  const token = data.token;

  // returns route if there is a valid token set in the cookie
  if (token) {
    return children;
  } else {
    // returns the user to the landing page if there is no valid token set

    alert("Please log in first");
    return <Navigate to={"/home"} />;
  }
}
