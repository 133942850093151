import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import "./bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Portfolio from "./pages/portfolio";
import Calandar from "./pages/calandar";
import Overview from "./pages/overview";
import Screener from "./pages/screener";
import Error from "./pages/error";
import Home from "./pages/home";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import axiosThrottle from "axios-request-throttle";
import ProtectedRoutes from "./ProtectedRoutes";
import Navbar from "./components/navbar";
import "./App.css";
import SettingsPage from "./pages/settings";
import Target from "./pages/target";
import { DataProvider } from "./DataContext";
import { DivProvider } from "./DivTotalContext";

axiosThrottle.use(axios, { requestsPerSecond: 5 });

function App() {
  const [portData, setPortData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [value, setValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  function calculateTotalPrice(data) {
    let totalPrice = 0;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      totalPrice += item.price * item.noOfShares;
    }

    return totalPrice;
  }

  function isNewDay() {
    // Retrieve the last loaded date from localStorage
    var lastLoadedDate = localStorage.getItem("lastLoadedDate");

    // Get the current date
    var currentDate = new Date().toLocaleDateString();

    // If lastLoadedDate is not set or it's different from the current date, it's a new day
    if (lastLoadedDate != currentDate) {
      // Store the current date in localStorage for future reference
      localStorage.setItem("lastLoadedDate", currentDate);
      axios
        .get(process.env.REACT_APP_API_UPDATE, {
          params: {
            user: JSON.parse(localStorage.getItem("data")).email,
            token: JSON.parse(localStorage.getItem("data")).token,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("data")).token
            }`,
          },
        })
        .then(() => setUpdate(!update))
        .catch((err) => console.log(err));
      return;
    }

    return;
  }

  useEffect(() => {
    setIsLoading(true);
    document.body.style.backgroundColor = "rgba(245,238,211,255)";

    if (JSON.parse(localStorage.getItem("data")).token) {
      isNewDay();
    }
    localStorage.getItem("data")
      ? axios
          .get(process.env.REACT_APP_API_GET, {
            params: {
              user: JSON.parse(localStorage.getItem("data")).email,
              token: JSON.parse(localStorage.getItem("data")).token,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("data")).token
              }`,
            },
          })
          .then((res) => {
            setIsLoading(false);
            setPortData(res.data);
            setValue(calculateTotalPrice(res.data));
          })
          .catch(() => {
            navigate("/home", { replace: true });
          })
      : console.log("Not Logged in yet");

    return;
  }, [update]);

  useEffect(() => {
    console.log(portData);
    //getDividendStocks();

    return;
  }, []);
  const location = useLocation();

  const getBackgroundClass = () => {
    switch (location.pathname) {
      case "/home":
        return "home-background";
      case "/portfolio":
        return "port-background";
      case "/calendar":
        return "cal-background";
      default:
        return "home-background";
    }
  };

  return (
    <DataProvider>
      <DivProvider>
        <div
          className={`App ${getBackgroundClass()}`}
          style={{ minWidth: 1100 }}
        >
          <Navbar />
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route
              path="portfolio"
              element={
                <ProtectedRoutes>
                  <Portfolio
                    data={portData}
                    setUpdate={setUpdate}
                    update={update}
                    value={value}
                    isLoading={isLoading}
                  />
                </ProtectedRoutes>
              }
            />
            <Route
              path="calendar"
              element={
                <ProtectedRoutes>
                  <Calandar data={portData} update={update} />
                </ProtectedRoutes>
              }
            />
            <Route
              path="overview"
              element={
                <ProtectedRoutes>
                  <Overview data={portData} value={value} />
                </ProtectedRoutes>
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedRoutes>
                  <SettingsPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="targets"
              element={
                <ProtectedRoutes>
                  <Target data={portData} />
                </ProtectedRoutes>
              }
            />
            <Route path="screener" element={<Screener />} />
            <Route path="error" element={<Error />} />

            <Route
              path="*"
              element={
                <div style={{ paddingTop: 100 }}>
                  <h1>PAGE NOT FOUND!</h1>
                </div>
              }
            />
          </Routes>
        </div>
      </DivProvider>
    </DataProvider>
  );
}

export default App;
